.badge {
    display: inline-block;
    font: var(--font-p3-medium-comp);
    border-radius: 8px;

    padding: 0 8px;
    margin-right: 8px;
}

.badge--dlc {
    color: var(--color-dlc);
    border: 2px solid var(--color-dlc);
}

.badge--preOrder {
    color: var(--color-pre-order);
    border: 2px solid var(--color-pre-order);
}
